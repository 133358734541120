var fullpage = require('./js/vendors/fullpage.min')

var logoB = document.getElementById('logo-b')
var logoC = document.getElementById('logo-c')

var navXSItems = [...document.querySelectorAll('.navigation__item')]
var myCheckbox = document.getElementById('navi-toggle')

var s2Items = [...document.querySelectorAll('p.s2-content-link')]
var s2Par = [...document.querySelectorAll('.s2-p-content')]
var s2Bg = [...document.querySelectorAll('div.s2-bg-content')]
var s2Title = [...document.querySelectorAll('h1.s2-title-content')]

var s5Items = [...document.querySelectorAll('p.s5-content-link')]
var s5Par = [...document.querySelectorAll('.s5-p-content')]
var s5Bg = [...document.querySelectorAll('div.s5-bg-content')]
var s5Title = [...document.querySelectorAll('h1.s5-title-content')]

var modal = document.getElementById('myModal')
var span = document.getElementsByClassName('close')[0]
var modalsC = document.querySelectorAll('.modal-s2a')

var modalArrowLeftA = document.querySelector('.modal-arrow-left-a')
var modalArrowRightA = document.querySelector('.modal-arrow-right-a')

var modalArrowLeftB = document.querySelector('.modal-arrow-left-b')
var modalArrowRightB = document.querySelector('.modal-arrow-right-b')

var modalArrowLeftC = document.querySelector('.modal-arrow-left-c')
var modalArrowRightC = document.querySelector('.modal-arrow-right-c')

var modalArrowLeftD = document.querySelector('.modal-arrow-left-d')
var modalArrowRightD = document.querySelector('.modal-arrow-right-d')

var product0 = document.querySelector('.product-0')
var modalC0 = document.querySelector('.modal-s2a-0')

var product1 = document.querySelector('.product-1')
var modalC1 = document.querySelector('.modal-s2a-1')

var product2 = document.querySelector('.product-2')
var modalC2 = document.querySelector('.modal-s2a-2')

var product3 = document.querySelector('.product-3')
var modalC3 = document.querySelector('.modal-s2a-3')

// var playVideo = document.querySelector('.play-video')
var video = document.querySelector('.videoContainer')

var fullpageConfig = {
	navigation: true,
	slidesNavigation: true,
	menu: '#myMenu',
	fixedElements: '#navigation',
	scrollBar: true,
	navigationTooltips: [
		'HOME',
		'About Us',
		'Products',
		'Suppliers, partners and some of our customers',
		'Added values',
		'Contact'
	],
	anchors: ['home', 'quienes', 'productos', 'proveedores', 'valores', 'contacto'],
	showActiveTooltip: true
}

if (document.body.clientWidth < 600) {
	fullpageConfig.autoScrolling = false
	fullpageConfig.responsiveWidth = 600
	var enableFP = false
	var sections = [...document.querySelectorAll('.section')]
	sections[0].id = 'home'
	sections[1].id = 'quienes'
	sections[2].id = 'productos'
	sections[3].id = 'proveedores'
	sections[4].id = 'valores'
	sections[5].id = 'contacto'
} else {
	new fullpage('#fullpage', fullpageConfig)
	var enableFP = true
}

var navbarChange = [...document.getElementsByClassName('navbar-change')]

window.onscroll = function () {
	if (enableFP) {
		var index = fullpage_api.getActiveSection().index
		if (index == 0 || index == 2) {
			navbarChange.forEach(function (el) {
				el.classList.add('text-white')
				el.classList.remove('text-main')
			})
			changeColor('white')

			logoB.classList.remove('hidden')
			logoC.classList.add('hidden')
		} else if (index == 3 || index == 5) {
			navbarChange.forEach(function (el) {
				el.classList.remove('text-white')
				el.classList.add('text-main')
			})
			changeColor('#325280')

			logoB.classList.add('hidden')
			logoC.classList.remove('hidden')
		} else if (index == 1 || index == 4) {
			navbarChange.forEach(function (el) {
				el.classList.remove('text-white')
				el.classList.add('text-main')
			})
			changeColor('white')

			logoB.classList.add('hidden')
			logoC.classList.remove('hidden')
		}
	}
}

document.getElementsByClassName('arrowDown')[0].addEventListener('click', function () {
	fullpage_api.moveSectionDown()
})

document.getElementsByClassName('arrowUp')[0].addEventListener('click', function () {
	fullpage_api.moveSectionUp()
})

s2Items.forEach(function (el, index) {
	el.addEventListener('click', function (e) {
		removeMyActive(s2Items)
		e.srcElement.classList.add('myActive')
		addHidden(s2Par)
		document.querySelector(`.s2-p-content-${index}`).classList.remove('hidden')
		addHidden(s2Bg)
		document.querySelector(`.s2-bg-content-${index}`).classList.remove('hidden')
		addHidden(s2Title)
		document.querySelector(`.s2-title-content-${index}`).classList.remove('hidden')
	})
})

s5Items.forEach(function (el, index) {
	el.addEventListener('click', function (e) {
		removeMyActive(s5Items)
		e.srcElement.classList.add('myActive')
		addHidden(s5Par)
		document.querySelector(`.s5-p-content-${index}`).classList.remove('hidden')
		addHidden(s5Bg)
		document.querySelector(`.s5-bg-content-${index}`).classList.remove('hidden')
		addHidden(s5Title)
		document.querySelector(`.s5-title-content-${index}`).classList.remove('hidden')

		if (index == 2) {
			changeColor('#325280')
		} else {
			changeColor('white')
		}
	})
})

span.addEventListener('click', function () {
	modal.style.display = 'none'
})

var autoSlider1, autoSlider2, autoSlider3, autoSlider4

function clearAllIntervals() {
	clearInterval(autoSlider1)
	clearInterval(autoSlider2)
	clearInterval(autoSlider3)
	clearInterval(autoSlider4)
}

product0.addEventListener('click', function () {
	modal.style.display = 'block'
	addHidden(modalsC)
	modalC0.classList.remove('hidden')
	clearAllIntervals()
	autoSlider1 = setInterval(slider1, 4000)
})

product1.addEventListener('click', function () {
	modal.style.display = 'block'
	addHidden(modalsC)
	modalC1.classList.remove('hidden')
	clearAllIntervals()
	autoSlider2 = setInterval(slider2, 4000)
})

product2.addEventListener('click', function () {
	modal.style.display = 'block'
	addHidden(modalsC)
	modalC2.classList.remove('hidden')
	clearAllIntervals()
	autoSlider3 = setInterval(slider3, 4000)
})

product3.addEventListener('click', function () {
	modal.style.display = 'block'
	addHidden(modalsC)
	modalC3.classList.remove('hidden')
	clearAllIntervals()
	autoSlider4 = setInterval(slider4, 4000)
})

// playVideo.addEventListener('click', function () {
// 	modal.style.display = 'block'
// 	addHidden(modalsC)
// 	video.classList.remove('hidden')
// })

document.onkeydown = function (evt) {
	evt = evt || window.event
	if (evt.keyCode == 27) {
		modal.style.display = 'none'
	}
}

var sliderPositionA = 0
var sliderPositionB = 0
var sliderPositionC = 0
var sliderPositionD = 0

function moveSlider1(n) {
	slider1((sliderPositionA += n))
}

function moveSlider2(n) {
	slider2((sliderPositionB += n))
}

function moveSlider3(n) {
	slider3((sliderPositionC += n))
}
function moveSlider4(n) {
	slider4((sliderPositionD += n))
}

function slider1(n) {
	var i
	var modalSlidesA = document.querySelectorAll('.modal-slide-a')
	if (n === undefined) {
		sliderPositionA++
	}
	if (n >= modalSlidesA.length || sliderPositionA >= modalSlidesA.length) {
		sliderPositionA = 0
	}
	if (n < 0 || sliderPositionA < 0) {
		sliderPositionA = modalSlidesA.length - 1
	}
	for (i = 0;i < modalSlidesA.length;i++) {
		addHidden(modalSlidesA)
	}
	modalSlidesA[sliderPositionA].classList.remove('hidden')
}

function slider2(n) {
	var i
	var modalSlidesB = document.querySelectorAll('.modal-slide-b')
	if (n === undefined) {
		sliderPositionB++
	}
	if (n >= modalSlidesB.length || sliderPositionB >= modalSlidesB.length) {
		sliderPositionB = 0
	}
	if (n < 0 || sliderPositionB < 0) {
		sliderPositionB = modalSlidesB.length - 1
	}
	for (i = 0;i < modalSlidesB.length;i++) {
		addHidden(modalSlidesB)
	}
	modalSlidesB[sliderPositionB].classList.remove('hidden')
}

function slider3(n) {
	var i
	var modalSlidesC = document.querySelectorAll('.modal-slide-c')
	if (n === undefined) {
		sliderPositionC++
	}
	if (n >= modalSlidesC.length || sliderPositionC >= modalSlidesC.length) {
		sliderPositionC = 0
	}
	if (n < 0 || sliderPositionC < 0) {
		sliderPositionC = modalSlidesC.length - 1
	}
	for (i = 0;i < modalSlidesC.length;i++) {
		addHidden(modalSlidesC)
	}
	modalSlidesC[sliderPositionC].classList.remove('hidden')
}

function slider4(n) {
	var i
	var modalSlidesD = document.querySelectorAll('.modal-slide-d')
	if (n === undefined) {
		sliderPositionD++
	}
	if (n >= modalSlidesD.length || sliderPositionD >= modalSlidesD.length) {
		sliderPositionD = 0
	}
	if (n < 0 || sliderPositionD < 0) {
		sliderPositionD = modalSlidesD.length - 1
	}
	for (i = 0;i < modalSlidesD.length;i++) {
		addHidden(modalSlidesD)
	}
	modalSlidesD[sliderPositionD].classList.remove('hidden')
}

modalArrowLeftA.addEventListener('click', function () {
	moveSlider1(-1)
})
modalArrowRightA.addEventListener('click', function () {
	moveSlider1(1)
})

modalArrowLeftB.addEventListener('click', function () {
	moveSlider2(-1)
})
modalArrowRightB.addEventListener('click', function () {
	moveSlider2(1)
})

modalArrowLeftC.addEventListener('click', function () {
	moveSlider3(-1)
})
modalArrowRightC.addEventListener('click', function () {
	moveSlider3(1)
})

modalArrowLeftD.addEventListener('click', function () {
	moveSlider4(-1)
})
modalArrowRightD.addEventListener('click', function () {
	moveSlider4(1)
})

navXSItems.forEach(function (el) {
	el.addEventListener('click', function () {
		myCheckbox.checked = false
	})
})

// FUNCTIONS

function removeMyActive(arr) {
	arr.forEach(function (el) {
		el.classList.remove('myActive')
	})
}

function addHidden(arr) {
	arr.forEach(function (el) {
		el.classList.add('hidden')
	})
}

var arrows = [...document.getElementsByClassName('arrow')]
var sideMenuSpan = [...document.querySelectorAll('#fp-nav ul li a span')]
var sideMenuTool = [...document.querySelectorAll('#fp-nav ul li .fp-tooltip')]

function changeColor(color) {
	var c = color === 'white' ? 'white' : '#325280'
	var cl = color === 'white' ? 'border-white' : 'border-main'

	if (cl === 'border-white') {
		arrows.forEach(function (el) {
			el.classList.add(cl)
			el.classList.remove('border-main')
		})
	} else {
		arrows.forEach(function (el) {
			el.classList.add(cl)
			el.classList.remove('border-white')
		})
	}
	sideMenuSpan.forEach((el) => {
		el.style.background = c
	})
	sideMenuTool.forEach((el) => {
		el.style.color = c
	})
}